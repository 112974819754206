import React from 'react'
// import { Link } from 'react-router-dom'
import "./SocialStickyLeft.css"
import useWindowSize from './useWindowSize';


function SocialStickyLeft() {

    const size = useWindowSize(); 

    return (
        
        <div className="social-wrapper">

        <div className="social-icons">
        {/* { size.width > 1300 &&
            <div className="icon-bg">
                
                <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            </div>
            } */}

            {/* { size.width > 1300 &&
            <div className="icon-bg">
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram'/>
            </Link>
            </div> */}
            
            {/*  */}
            {/* { size.width > 1300 &&

            <div className="icon-bg">
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            </div>
            } */}

            {/* { size.width > 1300 &&

            <div className="icon-bg">
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            </div>
            } */}



            { size.width > 1300 &&

            <div className="icon-bg">
            {/* <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            > */}
            <a class='social-icon-link twitter' href="https://www.linkedin.com/in/karima-targhi-bakkali-7022b1149/">
              <i class='fab fa-linkedin' />
            </a>
            {/* </Link> */}
            </div>
            }
            
       
        

        {/* { size.width > 1300 &&

            <div className="icon-bg">
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
            </div>
            } */}
            </div>
        </div>
        
    )
    }
   

export default SocialStickyLeft
