
import React from 'react'

import '../../App.css';
// import Cards from '../Cards';

// import HeroSection from '../HeroSection';
// import SectionLeft from '../SectionLeft';
// import SectionRight from '../SectionRight';
// import Box from '../Box';
// import HS from '../HS';
// import PRSectionRight from '../PRSectionRight';
// import PRSectionLeft from '../PRSectionLeft';
// import ScrollToTop from '../ScrollToTop';
// import HeroSectionLeft from '../HeroSectionLeft copy';
// import HeroSectionRight from '../HeroSectionRight';
// import Line from '../Line';
import Intro from './Intro';


function Home () {


    return(
       
        <>
        <Intro/>
        
        {/* <ScrollToTop>
    

        <HS/>
        
        
         <HeroSectionLeft img={require("../../images/EOThero2.jpg").default} alt="huh" 
     
    h1="Equitable designing" h5="I grew up with the word: Equality. It has always been a complex word to me. That's until I discovered the word: Equity. It radiates the core of my understanding what a design should have."/>
        

       <HeroSectionRight img={require("../../images/HomeS1.png").default} alt="huh" 
     
    h1="User Centered" h5="All designs are made for the users. Ask questions, receive feedback, update the design. A simple yet effective method to meet all ends."/>
         
        
        
       
       
        <Cards/>
        
        </ScrollToTop> */}
        
        </>
    )
}

export default Home;