import React from 'react'
import './PR3Img.css'
import useWindowSize from './useWindowSize'

function PR3Img() {

    const size = useWindowSize(); 

    return (
        <div className="container-3img">

        <div className="container-3img-wrap">
{ size.width > 768 &&
        <div className="section-image-3img">
            <div className="blokje-3img">
                <img src={require("../images/eot1.png").default} alt="kip" className="pic-3img"/>
        </div>  
            </div>
}

            <div className="section-image-3img">
            <div className="blokje-3img">
            <img src={require("../images/eot2.png").default} alt="kip" className="pic-3img"/>
            </div>
            </div>
            
 { size.width > 1300 &&
            <div className="section-image-3img">
            <div className="blokje-3img">
            <img src={require("../images/eot1.png").default} alt="kip" className="pic-3img"/>
            </div>
            </div> }

        </div>
 
            
        </div>
    )
}

export default PR3Img
