import React from 'react'
import '../../App.css';

import ContactForm from '../ContactForm';



function Contact () {
    return(
        <>
        
        <ContactForm/>
        
        </>
    )
}

export default Contact;