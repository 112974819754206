import React from 'react'
import './Line.css'

function Line() {
    return (
        <div className="container-line">
        <div className="wrap-line">
            <hr className="hr"></hr>
            </div>
        </div>
    )
}

export default Line
