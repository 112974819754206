import React from 'react'
import './Intropage.css'

function Intropage() {
    return (
        <>
        <div className="intro-container">
        <div className="intro-flexbox">    
        <h1>coming <b>soon</b></h1>
        <img className='intro-logo' alt="wave emoji" src={require("../images/K5.png").default}></img> </div>
        <img className='intro-txtlogo' alt="wave emoji" src={require("../images/logo2.png").default} ></img>
         
        </div>
        
            
        </>
    )
}

export default Intropage
