import React from 'react'
import './PRMockupText.css'

function PRMockupText(props) {
    return (
        <div className='container-mockuptext'>
        <div className="wrap-mockuptext">
        <div className="wrap-flex-mockuptext">
            
            <div className="image-mockuptext">
            <img src={props.img1} alt={props.alt1} className="pic-mockuptext"/>
            </div>
            
            <div className="text-mockuptext">
            {props.text1}
            </div>

        </div>


         <div className="wrap-flex-mockuptext">
            
            <div className="image-mockuptext">
            <img src={props.img2} alt={props.alt2} className="pic-mockuptext"/>
            </div>
            
            <div className="text-mockuptext">
            {props.text2}
            </div>

            </div>

        </div>
            
        </div>
    )
}

export default PRMockupText
