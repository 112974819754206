import React from 'react'

import './PRTitleText.css'

function PRTitleText(props) {
    return (
        
        <div className="container-titletext">
        
        <div className="wrap-titletext">
            <div className="title-titletext"> <h1>{props.title}</h1></div>
            <div className="text-titletext"> <h5>{props.text}</h5></div>
            
        </div>
        
        </div>
            
    )
}

export default PRTitleText

