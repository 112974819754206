import React from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';

import CardsLight from '../CardsLight';

export default function Projects(props) {
    return (
        <>
    <Link to='/Intro' style={{ textDecoration: 'none' }}>
     <h1 className="projects">Projects</h1>
     </Link>

    
    <CardsLight/>
    </>
     
    )
}