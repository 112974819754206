import React from 'react'
import './PRH5.css'

function PRH5(props) {
    return (
        <div className="container-prh5">
        <div className="container-prh5-wrap">
        <h5>{props.h5}</h5>


        </div>
            
        </div>
    )
}

export default PRH5
