import React from 'react'
import './PR1Img.css'

function PR1Img(props) {
    return (
        <div className="container-1img">

        <div className="container-1img-wrap">

           
            <img src={props.img} alt={props.alt} className="pic-1img"/>
            
    

        </div>
 
            
        </div>
    )
}

export default PR1Img
