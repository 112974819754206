import React from 'react';

import '../../App.css';
import PR1Img from '../PR1Img';
import PRTextLeft from '../PRTextLeft';

export default function About() {
    return (
<>
    <h1 className="services">About me</h1>
    <PR1Img/>
    <PR1Img img={require("../../images/Me2.jpg").default} alt="That's me standing in front of mountains"/>
    <PRTextLeft h1="Hi there," 
        h5="After working for 12+ years as a make-up artist, digital designing kept pulling me into it more & more. I always loved creativity and working with people. I have a strong sense of color theory, solving problems creatively, and love to affect people positively."
        h52="In my spare time I like to play video games, lift weights, and eat lovely food. I enjoy travelling to far places. "
        h53="For more info about my work/resume, please visit my" link="LinkedIn"
    />
    
</>
)
}
