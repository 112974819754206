import React from 'react'
import './SectionRight.css';
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from "framer-motion";





function HeroSectionRight(props) {
     const {inView, ref} = useInView();


     const animationControl = useAnimation();
     const checkAnimation = useAnimation();
    
     if (inView) {
    animationControl.start({
        
      x: 0,
      opacity: 1,
      transition: {
        delay: 0,
        ease: "easeInOut", 
        duration: 1.0
      }
    
      
    });

    
  }

  if (inView) {
    checkAnimation.start({
      y: "0px",
      opacity: 1,
      transition: {
        delay: 0,
        ease: "easeInOut", 
        duration: 0.5
      }
      
    });
  }




    return (
        
        <div className="sectionR"> 
        
        <div className='section-right-wrapperR' ref ={ref}>
        
         <motion.div

            initial={{
                 x: "500px",
                opacity: 0
        
            }}

            whileTap={{ scale: 0.9 }}

       

            animate={animationControl}
       
            className="section-imageR"
    >
    
        <img src={props.img} alt={props.alt} className="picR"/>
    
        </motion.div>
        
        
        <div ref={ref}>
         <motion.div

            initial={{
                y: "100px",
                opacity: 0
        
            }}

            animate={checkAnimation}
            className="section-textR"
            
    >

        {/* <h3>{props.label}</h3> */}
            <h1>
                {props.h1}
            </h1>
            

            <h5>{props.h5} </h5>
            </motion.div>
            
            
        </div>
            
        </div>
        </div>
    )
}

export default HeroSectionRight
