import React from 'react';
import '../../App.css';

// import HeroSectionLeft from '../HeroSectionLeft copy';
// import HeroSectionRight from '../HeroSectionRight';
import Line from '../Line';
import PR1Img from '../PR1Img';
// import PR3Img from '../PR3Img';
import PRH1 from '../PRH1';
// import PRH5 from '../PRH5';
import PRMockupText from '../PRMockupText';
import PRMockupText1 from '../PRMockupText1';
// import PRSectionLeft from '../PRSectionLeft';

import PRTextLeft from '../PRTextLeft';
import PrTitle from '../PrTitle';
import PRTitleText from '../PRTitleText';


export default function Project03() {
    return (
    <>
     <h1 className="projects2">Case Study</h1>
    <PrTitle text="Bogota" subtext="Responsive website where you can buy merch from a artist." date="September 2021"/>
    <Line/>
    <PR1Img img={require("../../images/BOhero.jpg").default} alt="Images displaying wireframes and mockups"/><Line/>
    <PRTitleText title="Project Goal" text="Easily buy official merchandise on artist's website. Make sure the userflow is easy to understand"/>   
    <Line/>
    <PRTextLeft h1="Project Overview" 
    h5="Almost everyone has it: a favorite artist. You can support your fav artist in multiple ways. One of those support systems is buying merchandise. Some people only want to buy merch on the official website, to avoid counterfeit products."
    h52="Bogota is a fictional artist, capturing hearts with his guitar skills and magical voice."
    />

<Line/>
<PRTitleText title="Designer Role" text="For this project, I took on the complete project as a UX Designer. From research to prototyping."/>
    <Line/>

<PRTitleText title="Target Users" text="The target users for this project are people aged 18+, who enjoy music and/or are concert goers. "/>
    <Line/>

<PRTitleText title="Key Challenges" text="The main challenge for this project is creating a functional, easy user flow, when buying merchandise on a website.  "/>
    <Line/>

    <PRTextLeft h1="Research Study" 
    h5="For the research study, I interviewed 5 individuals. The group was quite diverse, but many answers were corresponding with each other."
    h52="All participants said they dislike websites with too much text, pictures and such. 80% preferred buying merch of the official website. 80% would like to see information like tourdates on a website."
    h53=""
    />
    <Line/>
    

    <PRH1 h1="Initial design concepts"/>
    <PRMockupText  img1={require("../../images/BOWire1.png").default} alt1="Displaying wireframe Website, Homescreen" 
    text1="Initial home screen. The word 'merch' in the navbar has been replaced by 'Shop'. Tourdates are also added to the navbar after the research study."

    img2={require("../../images/BOWire2.png").default} alt2="Displaying wireframe app" 
    text2="Item screen."
    />

     <PRMockupText1 img1={require("../../images/BOWire3.png").default} alt1="Wireframe mobile"
    text1="The website is responsive and will transform based on the media used to view the website. Above is a wireframe for the home screen on mobile displays."
    />

    <Line/>

    <PRTitleText title="User Tests" 
    text="I conducted a usability study to see the reactions and first inputs of the participants. The tests were conducted in a one-to-one environment. All participants were asked to analyze and use a prototype. "/>
    <Line/>

    <PRTitleText title="Test Results" 
    text="The two main points in the results that were standing out were: the word 'Merch' could be difficult to understand, and adding more information (like tourdates) "/>
    <Line/>

    <PRH1 h1="Prototype Design"/>
    <PR1Img img={require("../../images/BOProto.jpg").default} alt="Images displaying wireframes and mockups"/>
    <Line/>

    <PRMockupText  img1={require("../../images/BOProto1.png").default} alt1="Displaying prototype app" 
    text1="On the desktop website, after choosing a product you like to buy, you will get options for customization. You can select a color, and the option box has the placeholder text 'Choose Size'. "

                img2={require("../../images/BOProto2.png").default} alt2="Displaying prototype app"
                text2="After getting a pop-up confirmation of adding your item to the cart, you can view your cart. You can add, delete, or confirm your order."
            />

    <PRMockupText  img1={require("../../images/BOProto3.png").default} alt1="Displaying prototype app" 
    text1="After collecting all your user data, or filling in shipping info, a review screen will show all your data again, and the total amount due."

    img2={require("../../images/BOProto4.png").default} alt2="Displaying prototype app" 
    text2="Lastly, a success screen will be the final confirmation of this flow."
    />

   
    
    <Line/>

    <PRTextLeft h1="Conclusion" 
    h5="Creating a webshop - environment sounds very easy and common, because we use it so much. But I learned it has a lot of opportunities for user experience. You can add so many options, but you want to have a clean and easy to read interface."
    h52="I would love to keep on working on e-commerce projects, I think it's a excellent challenge of creating a well executed user experience."
    />
    <Line/>
    <PRTitleText title="Next steps" text="I would like to make the buying process even more pleasant by adding more pictures of the products. Like a carrousel gallery. I also would like to dive deeper into confirmation screen, to see with types work the best."/>
    {/* <Line/> */}



    
    {/* <HeroSectionRight img={require("../../images/WNLhero.jpg").default} alt="huh" 
    h1="The Future" h5="As this was a personal project, I hope I can use this experience to help with a similar problem in the future."/> */}

    </>
    
    )
}