import React from 'react'
import './PrTitle.css'

function PrTitle(props) {
    return (
        <div className="container-prtitle">
        <div className="container-prtitle-wrap">

          <h1 >{props.text}</h1>  
          <h2>{props.subtext}</h2>
          <h3>{props.date}</h3>
        </div>
        
        </div>
    )
}

export default PrTitle
