import React from 'react'
import './PRH1.css'

function PRH1(props) {
    return (
        <div className="container-prh1">
        <div className="container-prh1-wrap">
        <h1>{props.h1}</h1>


        </div>
            
        </div>
    )
}

export default PRH1
