import React from 'react';
import { Link } from 'react-router-dom';


function CardItem(props) {
    return (
        <>
          
          <Link className="cards-item-linkL" to={props.path}>
              <figure className="cards-item-pic-wrapL" data-category={props.label}>
                  <img src={props.src} 
                  alt="Iets" 
                  className="cards-item-imgL" />
              </figure>
              <div className="cards-item-infoL">
                  <h5 className='cards-item-textL'>{props.text}</h5>
                  <h6 className='cards-item-text2L'>{props.text2}</h6>
              </div>
          </Link>

        </>
    )
}

export default CardItem
