import React from 'react';
import '../../App.css';

import HeroSectionLeft from '../HeroSectionLeft copy';
import HeroSectionRight from '../HeroSectionRight';
import Line from '../Line';
import PR1Img from '../PR1Img';
// import PR3Img from '../PR3Img';
import PRH1 from '../PRH1';
import PRH5 from '../PRH5';
import PRMockupText from '../PRMockupText';
import PRMockupText1 from '../PRMockupText1';
// import PRSectionLeft from '../PRSectionLeft';

import PRTextLeft from '../PRTextLeft';
import PrTitle from '../PrTitle';
import PRTitleText from '../PRTitleText';


export default function Project02() {
    return (
    <>
     <h1 className="projects2">Case Study</h1>
    <PrTitle text="Work NL" subtext="Responsive website/app that helps first generation immigrants find the perfect job." date="October 2021"/>
    <Line/>
    <PR1Img img={require("../../images/WNLhero2.jpg").default} alt="Images displaying wireframes and mockups"/><Line/>
    {/* <PR3Img/><Line/> */}
    <HeroSectionLeft img={require("../../images/HomeS1.png").default} alt="Displaying the website on different media." 
    // label="Mockup" 
    h1="Project Goal" h5="Creating a simple, readable, straight to the point responsive website, to help people find a perfect (new) job."/>

   
    <Line/>
    <PRTextLeft h1="Project Overview" 
    h5="First generation Immigrants are often viewed as hard workers. In the Netherlands, a lot of immigrants have different origins. Together they make up more than 10% of the Dutch population. A huge portion of this group have the same (type of) job since entering the country. Now that the average age is rising, some people can’t do the hard labour anymore, and need another job. 
For a lot of immigrants, the language, new technology, and the lack of information can be a big challenge."
    h52="Work NL is build to aid the first generation immigrants. But it's accessible for everyone."
    />

<Line/>
<PRTitleText title="Designer Role" text="For this project, I took on the complete project as a UX Designer. From research to prototyping."/>
    <Line/>

<PRTitleText title="Target Users" text="The target users for this app are first generation immigrants in The Netherlands. Ages from 18 till retirement. "/>
    <Line/>

<PRTitleText title="Key Challenges" text="The main challenge for this app was how to cater to such a diverse, and large group of different people. What are the needs, which problems are they facing right now?"/>
    <Line/>

    <PRTextLeft h1="Research Study" 
    h5=" In the first part of the study, I studied demographics of the first generation immigrants. I used CBS’s studies on immigrants in The Netherlands. I noticed a big difference between European and non-European first immigrants. Non-European immigrants have a large group above the age 50."
    h52="I interviewed both European and non-European immigrants, but I noticed that language problems are common between both groups. Both groups feel like job search sites are often focussed on non-immigrants and often written in difficult language. Both groups are interested in a more easy to understand platform."
    h53=""
    />
    <Line/>
    
    <PR1Img img={require("../../images/cbs.jpg").default} alt="Showing statistics"/>
    <PRH5 h5=" ^ 13,7% of all inhabitants are first generation immigrants" />
    <Line/>

    <PRH1 h1="Initial design concepts"/>
    <PR1Img img={require("../../images/WNLwireframe.jpg").default} alt="Images displaying wireframes and mockups"/>
    <Line/>

    <PRTitleText title="Competitive Audit" 
    text="After analyzing some popular job-searching websites, I added a prominent searchbar to the design. 
    I concluded that a lot of the websites have a lot of text, but not many images to simplify the process.
    Because language problems are a challenge, I wanted to add more icons and images to the design, to differentiate from the other job searching sites."/>
    <Line/>

    <PRTitleText title="User Tests" 
    text="I conducted a usability study to see the reactions and first inputs of the participants. The tests were conducted in a one-to-one environment. All participants were asked to analyze and use a prototype. "/>
    <Line/>

    <PRTitleText title="Test Results" 
    text="The main issues found were: Some people had difficulty to go back to the mainpage."/>
    <Line/>

    <PRH1 h1="Prototype Design"/>
    <PR1Img img={require("../../images/WNLProto.jpg").default} alt="Images displaying wireframes and mockups"/>
    <Line/>

    <PRMockupText  img1={require("../../images/WNLProto1.png").default} alt1="Displaying prototype app" 
    text1="On the app, the home screen has a easy language selector, a prominent searchbar to make the job hunt easier, but also job recommendations. The recommendations show pictures related to the job, so it will be easier to understand."

                img2={require("../../images/WNLProto2.png").default} alt2="Displaying prototype app"
                text2="When clicking on a job listing, the job summary is always backed up by icons on top. The most important attributes are listed on top as icons + text. An 'Apply Now' button is positioned below and will keep the position when scrolling"
            />

    <PRMockupText  img1={require("../../images/WNLProto3.png").default} alt1="Displaying prototype app" 
    text1="When clicking the apply button, a pop-up confirmation message will be in screen. You can confirm that you really wanted to apply at this time. "

    img2={require("../../images/WNLProto4.png").default} alt2="Displaying prototype app" 
    text2="Lastly, the succes screen, after confirming the application."
    />

    <PRMockupText  img1={require("../../images/WNLProtoLap1.png").default} alt1="Displaying prototype Website, Homescreen" 
    text1="On the website desktop version, all functions are on the navbar. The navbar will be sticky, so that everything will be easy to attain."

    img2={require("../../images/WNLProtoLap2.png").default} alt2="Displaying prototype app" 
    text2="All pages are accompanied by a header text. You can immediately spot which page you are on. Because the pages are responsive, they look familiar even when you use a different media device."
    />

    <PRMockupText1 img1={require("../../images/WNLProtoIpad.png").default} alt1="Prototype on a pad device"
    text1="The responsive design will trigger a different look based on the medium used. Above is the website on a pad."
    />
    
    <Line/>

    <PRTextLeft h1="Conclusion" 
    h5="To me, this project was super interesting. I never imagined that we have such a large group of first generation immigrants. It was really refreshing to learn about the subject, and creating solutions for a fairly common problem that (in my opinion) is quite overlooked. I am sure that not only first generation immigrants would benefit from this project."
    h52="The main take-away was the importance of the use of language. To keep away from difficult language, or to use images/icons to back-up the language."
    />
    <Line/>
    <PRTitleText title="Next steps" text="The project can be even stronger by conduction more usability tests. For example with people who have trouble to read. The app also would benefit if more icons are added (for example, on the confirmation screen)."/>
    <Line/>



    
    <HeroSectionRight img={require("../../images/WNLhero.jpg").default} alt="huh" 
    h1="The Future" h5="As this was a personal project, I hope I can use this experience to help with a similar problem in the future."/>

    </>
    
    )
}