import React from 'react'
import CardItemLight from './CardItemLight'
import './CardsLight.css'



function CardsLight() {

    
    
    return (
        <div className='cardsL'>
            <h1>View and read my latests projects.</h1>
            <div className="cards-containerL">
                
                <div className="cards-itemsL">

                <div className="itemL">
                
                <CardItemLight
                    src={require("../images/PR-Card-eot-1.jpg").default}
                    text="Eat on Time"
                    text2="An app that reminds you to eat"
                    label="App"
                    path="/Project01"
                    
                />
                </div>

                <div className="itemL">
                <CardItemLight
                    src={require("../images/PR-Card-wnl-1.jpg").default}
                    text="Work NL"
                    text2="Project that helps first generation immigrants find the perfect job."
                    label="Responsive website + app"
                    path="/Project02"
                />
                </div>
                
                </div>

                <div className="cards-itemsL">
                
                

                <div className="itemL">
                 <CardItemLight
                    src={require("../images/PR-Card-bo-1.jpg").default}
                    text="Bogota"
                    text2="Merch-selling artist website"
                    label="Website"
                    path="/Project03"
                    
                />
                </div>
                
                 
                 <div className="itemL">
                 <CardItemLight
                    src={require("../images/PR-Card-port-1.jpg").default}
                    text="This website"
                    text2="Portfolio website coded by me"
                    label="React.js"
                    path="/Project04"
                    
                />
                </div>
                
                
                </div>
               
                
                </div>
            </div>
        
    )
}

export default CardsLight
