import React from 'react'
import Intropage from '../Intropage'

function Intro() {
    return (
        <>
            <Intropage/>
        </>
    )
}

export default Intro
