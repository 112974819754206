import React from 'react'
import './PRTextLeft.css'

function PRTextLeft(props) {
    return (
        <div className="container-prtext">
        <div className="container-prtext-wrap">
        <h1>{props.h1}</h1>
        <h5>{props.h5}</h5>
        <h5>{props.h52}</h5>
        <h5>{props.h53} <a href="https://www.linkedin.com/in/karima-targhi-bakkali-7022b1149/">{props.link}</a></h5>
        <h6>{props.h6}</h6>


        </div>
            
        </div>
    )
}

export default PRTextLeft
