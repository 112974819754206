import React, { useState, useRef } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com'
import Reaptcha from 'reaptcha';

const Result =() =>{
  return(
    <p className='p3'>Thank you! Message is send.</p>
  )
}

const Disable =() =>{
  return(
    <p className="p">* Please complete the Recaptcha before sending!</p>
  )
}

const Able =() =>{
  return(
    <p className="p2">K</p>
  )
}


function ContactForm() {

 
  
  const [result, showResult] = useState(false);

  const [disableSubmit,setDisableSubmit] = useState(true);
 

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lvap7he', 'template_3w49q6v', form.current, 'user_Pr23lepidZuO63RnsCYyX')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      form.current.reset();
      showResult(true);
  };

  



  return (
    <div>
      <div className="contactpage">
      <h1 className="contact">Contact</h1>
       <form ref={form} onSubmit={sendEmail}>
       

       <h1 className="h1form"> Send me a message</h1>
      
      <div className="container">
      <img className='img' alt="stamp with logo" src={require("../images/stamp3.png").default}/>
      <div className="form">
      <label className="label">Name</label>
      <input type="text" name="user_name" placeholder="Your Name" className="text"/>
      

      <label className="label">Email</label>
      <input type="email" name="user_email" className="email" placeholder="Your Email"/>

      <label className="label2">Message</label>
      <textarea name="message" className="message" placeholder="Your Message <3"/>
      
      {/* <ReCaptchaV2 sitekey={'6Lcu-DQdAAAAAHkDEoCmi5VT-SeQhM-VET8Lh0JC'} 
        onChange={(() => setDisableSubmit(false))}
        onExpire={(() => setDisableSubmit(true))}
      /> */}
      <Reaptcha sitekey="6Lcu-DQdAAAAAHkDEoCmi5VT-SeQhM-VET8Lh0JC" onVerify={(() => setDisableSubmit(false))} onExpire={(() => setDisableSubmit(true))} />

      <input className="submit" type="submit" disabled={disableSubmit} value="Send" />
      <div className="row">
        {result ? <Result /> : null}
        {disableSubmit ? <Disable/> : <Able/>}
        
        
      
      </div>
       </div>
       </div>
    </form>



      </div>
    </div>
  );
}

export default ContactForm;