import React from 'react';
// import Navbar from './components/Navbar';

import Home from './components/Pages/Home'
// import Footer from './components/Footer';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";


import Contact from './components/Pages/Contact';
import SocialStickyLeft from './components/SocialStickyLeft';

import Projects from './components/Pages/Projects';
import Project01 from './components/Pages/Project01';
import Project02 from './components/Pages/Project02';
import ScrollToTop from './components/ScrollToTop';
import About from './components/Pages/About';
import Project03 from './components/Pages/Project03';
import Project04 from './components/Pages/Project04';
import Intro from './components/Pages/Intro';

function App() {
  return (
    <div className="scroll">
    <Router>
      {/* <h1>Hello</h1> */}
      <ScrollToTop>

      {/* <Navbar /> */}
      <SocialStickyLeft/>
      
            <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/about' exact component={About} />
        <Route path='/projects' exact component={Projects} />
        <Route path='/contact' exact component={Contact} />
        <Route path='/Intro' exact component={Intro} />
        <Route path='/Project01' exact component={Project01} />
        <Route path='/Project02' exact component={Project02} />
        <Route path='/Project03' exact component={Project03} />
        <Route path='/Project04' exact component={Project04} />
      </Switch>
      {/* <Footer/> */}
      </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
