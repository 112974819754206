import React from 'react';
import '../../App.css';

import HeroSectionLeft from '../HeroSectionLeft copy';
import HeroSectionRight from '../HeroSectionRight';
import Line from '../Line';
import PR1Img from '../PR1Img';
import PR3Img from '../PR3Img';
import PRH1 from '../PRH1';
// import PRSectionLeft from '../PRSectionLeft';

import PRTextLeft from '../PRTextLeft';
import PrTitle from '../PrTitle';
import PRTitleText from '../PRTitleText';


export default function Project01() {
    return (
    <>
     <h1 className="projects2">Case Study</h1>
    <PrTitle text="Eat On Time" subtext="An app that helps you remind to eat, on time." date="June 2021"/>
    <Line/>
    <PR1Img img={require("../../images/EOThero2.jpg").default} alt="Images displaying wireframes and mockups"/><Line/>
    <PR3Img/><Line/>
    <HeroSectionLeft img={require("../../images/EOTpic2.jpg").default} alt="Displaying the app on phone while hand holding the phone" 
    // label="Mockup" 
    h1="Project Goal" h5="Simplify the process of creating personalized reminders to eat. Creating easy schedules without much work."/>

   
    <Line/>
    <PRTextLeft h1="Project Overview" 
    h5="During the pandemic of 2021, a lot of people were forced to change their work schedules completely. Many people had to work from home, which changed their work schedule completely. I noticed a lot of people complaining about forgetting to eat, or eat on random times. I also noticed that this is a common thing for people who create their own schedules. I used this phenomenon to create this project."
    h52="Eat on Time is an app that will help you set reminders to eat. You can add times for reminders as you like, and you can even set Presets to make your schedule for a working day easy. The main user for this app are people who are too busy, or just blatantly forget to eat."
    />

<Line/>
<PRTitleText title="Designer Role" text="For this project, I took on the complete project as a UX Designer. From research to prototyping."/>
    <Line/>

<PRTitleText title="Target Users" text="The target users for this app are the people who are really busy and forget to eat. Or are easily distracted and need a reminder."/>
    <Line/>

<PRTitleText title="Key Challenges" text="The main challenge for this app was how to create easy, non-binding reminders that are considered to be helpful instead of feeling like a routine or obligation."/>
    <Line/>

    <PRTextLeft h1="Research Study" 
    h5="In the research study we interviewed 4 people to see if they could relate to the problem of not eating on time, and to see what we could do to help. We were positively shocked how many people considered this a problem. It is an issue that’s fairly common. We saw this trend within all ages and genders. Their main concern was that they have no one to remind them to eat. And sometimes they prioritize work over eating."
    h52="The main pain points we found were: Prioritizing work over eating, Missing a reminder, Other food apps focus more on weight loss/gain than eating on a certain time."
    h53="After learning this, I build a wireframe design based on those 3 pain points."
    />
    <Line/>
    
    <PRH1 h1="Initial design concepts"/>
    <PR1Img img={require("../../images/lofiproto.jpg").default} alt="Images displaying wireframes and mockups"/>
    <Line/>

    <PRTitleText title="User Tests" 
    text="I conducted a usability study to see the reactions and first inputs of the participants. The tests were conducted in a one-to-one environment. All participants were asked to analyze and use a prototype. "/>
    <Line/>

    <PRTitleText title="Test Results" 
    text="The main issues found were: Clickables/buttons are small, No presets were available, App needs a fun element"/>
    <Line/>

    <PRH1 h1="Prototype Design"/>
    <PR1Img img={require("../../images/EOTProto1.jpg").default} alt="Images displaying wireframes and mockups"/>
    <Line/>

    <PRTextLeft h1="Conclusion" 
    h5="The app really sparked interested in people. They would love to have it in real life. I learned how many people could benefit from it. (even with medication instead of food)"
    h52="I also noticed how many people prefer wider, bigger buttons. They are more accessible, even if they are in a hard to reach corner of the screen."
    />
    <Line/>
    <PRTitleText title="Next steps" text="To enhance the idea/ design of this product, adding a option with ideas what to eat would also be beneficial. I also want to add a visual aspect to the lower menu/icons, so you can see on which page you are right now. "/>
    <Line/>



    
    <HeroSectionRight img={require("../../images/EOTpic2.jpg").default} alt="huh" 
    h1="Interested?" h5="Would you like to hear more? Please don't hesitate to send me a message. "/>

    </>
    
    )
}