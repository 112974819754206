import React from 'react';
import '../../App.css';
import Line from '../Line';
import PR1Img from '../PR1Img';
import PRH1 from '../PRH1';
import PRTextLeft from '../PRTextLeft';
import PrTitle from '../PrTitle';



export default function Project04() {
    return (
    <>
     <h1 className="projects2">About</h1>
    <PrTitle text="This website" subtext="Responsive portfolio website build with React" date="January 2022"/>
    <Line/>
    <PR1Img img={require("../../images/PORThero.jpg").default} alt="Images displaying wireframes and mockups"/><Line/>
    

    <PRTextLeft h1="Project Overview" 
    h5="It was time to showcase my work. I tried a few web builders, but they missed 'something' I wanted on the site. Why not build my own website? I know some HTML after all... Well, maybe i need something more. "
    h52="And then I came to the brilliant idea of taking a first step into React! It took some time, but i can happily say that I learned a lot."
    h53="It was very nice to translate your own wireframes into a website, and to actually make your User Experience come to life. On the journey I noticed a lot of User Experience technicalties that I had to fix with code. The biggest challenge was responsiveness, for all screens. All dimensions. All phones. I resized my browser a gazillion times, and I used mobile simulators to check the most common phone dimensions."
    />

    <PRH1 h1="Hope you enjoyed it!" />
    <Line/>
    </>
    )
}